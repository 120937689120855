import { React, useState, useEffect, useContext } from "react";
import VideoList from "../VideoList";
import {
	Card,
	Row,
	Col,
	CardBody,
	CardDeck,
	CardTitle,
	CardText,
	CardImg,
	CardImgOverlay,
} from "reactstrap";

const Videos = (props) => {
	return (
		<>
			<div
				className="Card"
				style={{
					backgroundColor: "transparent",
				}}>
				<div className="m-2">
					<Card className="card animate__animated animate__fadeInUp">
						<CardBody>
							<CardTitle tag="h2">{props.title}</CardTitle>
							<Row className="d-flex flex-row justify-content-center align-items-center">
								<Col sm="12">
									<div>
										<CardText tag="h4">
											We are alogrithmic beings on a technological journey. Get
											captivated by the marvels of nature and technology.
										</CardText>
									</div>
								</Col>

								<Row className="d-flex flex-row justify-content-center align-items-center pt-3">
									<Col sm="3">
										<div className="bar1">Plan</div>
									</Col>
									<Col sm="3">
										<div className="bar2">Develop</div>
									</Col>
									<Col sm="3">
										<div className="bar3">Innovate</div>
									</Col>
									<Col sm="3">
										<div className="bar4">Grow</div>
									</Col>
								</Row>
							</Row>
							<Row className="d-flex flex-row justify-content-center align-items-center p-1">
								<VideoList data={props.videodata} />
							</Row>
						</CardBody>
					</Card>
				</div>
			</div>
		</>
	);
};

export default Videos;

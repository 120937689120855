import { React, useState } from "react";
import { useMediaQuery } from "react-responsive";
import logo from "./logo.svg";
import "./App.css";
import {
	Row,
	Col,
	Button,
	Card,
	CardBody,
	CardDeck,
	CardTitle,
	CardText,
	CardImg,
	CardImgOverlay,
} from "reactstrap";
import Accord from "./Accordian";
import { FiTwitter, FiLinkedin, FiFacebook } from "react-icons/fi";
import { Margin } from "@mui/icons-material";

const Footer = () => {
	const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

	return isMobile ? (
		<div
			style={{
				zIndex: "1",
				position: "fixed",
				marginTop: "90vh",
				marginLeft: "25%",
			}}>
			<Row className="d-flex flex-row justify-content-center align-items-center">
				<Col className="d-flex flex-row justify-content-center align-items-center">
					<a href="https://twitter.com/bitnnovative">
						<FiTwitter className="social-media" color="#ff9900" size={24} />
					</a>

					<a href="https://www.facebook.com/bitnnovative">
						<FiFacebook className="social-media" color="#ff9900" size={24} />
					</a>

					<a href="https://www.linkedin.com/company/bitnnovative/">
						<FiLinkedin className="social-media" color="#ff9900" size={24} />
					</a>
				</Col>
			</Row>
			<Row>
				<Col className="d-flex flex-row justify-content-center align-items-center">
					<p style={{ color: "white" }}>© Copyright Bitnnovative,2023</p>
				</Col>
			</Row>
		</div>
	) : (
		<div className="Footer d-flex flex-row justify-content-center align-items-center">
			<div>
				<Row className="d-flex flex-row justify-content-center align-items-center">
					<Col sm="2">
						<a href="https://twitter.com/bitnnovative">
							<FiTwitter className="social-media" color="#ff9900" size={24} />
						</a>
					</Col>
					<Col sm="2">
						<a href="https://www.facebook.com/bitnnovative">
							<FiFacebook className="social-media" color="#ff9900" size={24} />
						</a>
					</Col>
					<Col sm="2">
						<a href="https://www.linkedin.com/company/bitnnovative/">
							<FiLinkedin className="social-media" color="#ff9900" size={24} />
						</a>
					</Col>
				</Row>
				<Row>
					<p style={{ color: "white" }}>© Copyright Bitnnovative,2023</p>
				</Row>
			</div>
		</div>
	);
};

export default Footer;

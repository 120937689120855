import { React, useState } from "react";

import "./icon_anim.css";
import {
	FiHome,
	FiLinkedin,
	FiFacebook,
	FiSettings,
	FiFile,
	FiFolder,
	FiVideo,
	FiPhone,
	FiCloudLightning,
	FiMoon,
	FiDatabase,
	FiCodesandbox,
	FiCode,
	FiCloud,
	FiCpu,
	FiServer,
} from "react-icons/fi";
import { useEffect } from "react";

function IconAnim() {
	return (
		<>
			<div className="icon-container">
				<FiCodesandbox
					className="image icon-content"
					color="#ff9900"
					size={16}
				/>
				<FiCode className="image4 icon-content" color="#ff9900" size={16} />
				<FiCloud className="image5 icon-content" color="#ff9900" size={16} />
				<FiServer className="image6 icon-content" color="#ff9900" size={16} />
			</div>
		</>
	);
}

export default IconAnim;

import { React, useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, CardTitle, CardText, Row, Col } from "reactstrap";
import { FiHeart, FiArrowRightCircle } from "react-icons/fi";
import blog_img from "./images/blog_img.png";

const BlogItem = ({ navigate, ...props }) => {
	const handleChange = () => {
		navigate(`/blog/${props.post.id}`);
	};
	return (
		<Card className="card animate__animated animate__fadeInUp m-3">
			<CardBody>
				<CardTitle tag="h2">{props.title}</CardTitle>
				<Row className="d-flex flex-row justify-content-center align-items-center">
					<Col sm="4">
						<div>
							<img
								style={{ height: "200px", borderRadius: 30 }}
								src={props.post.url}
								alt="Image"
							/>
						</div>
					</Col>
					<Col sm="4">
						<div>
							<CardText tag="h1">{props.post.title}</CardText>
						</div>
					</Col>
					<Col sm="2">
						<div>
							<CardText tag="h4">{props.post.date}</CardText>
						</div>
					</Col>

					<Col sm="2">
						<div onClick={handleChange}>
							<FiArrowRightCircle size="38px" />
						</div>
					</Col>
				</Row>
			</CardBody>
		</Card>

		// <a href={props.video.url}>
		// 	<h3>{props.video.title}</h3>
		// 	<p>{props.video.description}</p>
		// </a>
	);
};

export default BlogItem;

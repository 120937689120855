import { React } from "react";
import CommentItem from "../CommentItem";
import {
	Card,
	Row,
	Col,
	CardBody,
	CardDeck,
	CardTitle,
	CardText,
	CardImg,
	CardImgOverlay,
} from "reactstrap";

const CommentSection = ({
	data,
	fetchComments,
	downloadComments,
	videoUrl,
	setVideoUrl,
	isLoading,
	error,
}) => {
	return (
		<section>
			<Row className="d-flex flex-row justify-content-center align-items-center m-3">
				<Col sm="6">
					<div>
						<input
							type="text"
							value={videoUrl}
							onChange={(e) => setVideoUrl(e.target.value)}
							placeholder="Enter YouTube video URL"
						/>
					</div>
					<div>
						<button onClick={fetchComments} disabled={isLoading}>
							{isLoading ? "Loading..." : "Get Comments"}
						</button>
					</div>
				</Col>
			</Row>

			{error && <p style={{ color: "red" }}>{error}</p>}

			{data &&
				data.comments.map((item, index) => (
					<CommentItem key={index} comment={item} />
				))}
		</section>
	);
};

export default CommentSection;

import React, { useState, useEffect, useForm, useRef } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import "./App.css";

import emailjs from "@emailjs/browser";

const ContactForm = (props) => {
	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs
			.sendForm(
				"service_p8rmwcf",
				"template_ju1gf9f",
				form.current,
				"BsKju9QExPizJeGY-",
			)
			.then(
				(result) => {
					console.log(result.text);
					props.messageSent();
				},
				(error) => {
					console.log(error.text);
				},
			);
	};

	return (
		<form className="w-100" ref={form} onSubmit={sendEmail}>
			<label style={{ marginRight: "100%" }}>Name</label>
			<input required type="text" name="user_name" placeholder="Name" />

			<label style={{ marginRight: "100%" }}>Email</label>
			<input required type="email" name="user_email" placeholder="Email" />

			<label style={{ marginRight: "100%" }}>Message</label>
			<textarea name="message" placeholder="Please share your question" />

			<input type="submit" value="Send" />
		</form>
	);
};

export default ContactForm;

import React, { useState } from "react";
import Webcard from "./Card";
import { useMediaQuery } from "react-responsive";
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Alert,
} from "reactstrap";

import Iframe from "react-iframe";

const Accord = (props) => {
	const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
	const [open, setOpen] = useState("");
	const toggle = (id) => {
		if (open === id) {
			setOpen();
		} else {
			setOpen(id);
		}
	};

	return (
		<div>
			<Accordion flush open={open} toggle={toggle}>
				<AccordionItem>
					<AccordionHeader targetId="1">Business Profile</AccordionHeader>
					<AccordionBody accordionId="1">
						{isMobile ? (
							<Iframe
								url="https://heyzine.com/flip-book/a67f4ab49c.html"
								width="280px"
								height="500px"
								id="b_profile"
								className="profile"
								display="inline-block"
								position="relative"
							/>
						) : (
							<Iframe
								url="https://heyzine.com/flip-book/a67f4ab49c.html"
								width="1024px"
								height="640px"
								id="b_profile"
								className="profile"
								display="inline-block"
								position="relative"
							/>
						)}
					</AccordionBody>
				</AccordionItem>
				{/* <AccordionItem>
					<AccordionHeader targetId="2">Accordion Item 2</AccordionHeader>
					<AccordionBody accordionId="2">
						<strong>This is the second item&#39;s accordion body.</strong>
						You can modify any of this with custom CSS or overriding our default
						variables. It&#39;s also worth noting that just about any HTML can
						go within the <code>.accordion-body</code>, though the transition
						does limit overflow.
					</AccordionBody>
				</AccordionItem>
				<AccordionItem>
					<AccordionHeader targetId="3">Accordion Item 3</AccordionHeader>
					<AccordionBody accordionId="3">
						<strong>This is the third item&#39;s accordion body.</strong>
						You can modify any of this with custom CSS or overriding our default
						variables. It&#39;s also worth noting that just about any HTML can
						go within the <code>.accordion-body</code>, though the transition
						does limit overflow.
					</AccordionBody>
				</AccordionItem> */}
			</Accordion>
		</div>
	);
};

export default Accord;

import { React, useState, useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";

import BlogItem from "../BlogItem";
import { useNavigate } from "react-router-dom";

const Blog = (props, emptyHeading) => {
	console.log("in blog list", props.data);
	const navigate = useNavigate();

	const count = 0;
	let heading = emptyHeading;
	if (count > 0) {
		const noun = count > 1 ? "Posts" : "Post";
		heading = count + " " + noun;
	}

	return (
		<section>
			{/* <h2>{heading}</h2> */}

			{props.data &&
				props.data.posts.map((item, index) => (
					<BlogItem key={index} post={item} navigate={navigate} />
				))}
		</section>
	);
};

export default Blog;

import { NavLink } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Tooltip } from "react-tooltip";
import "./App.css";
import {
	Row,
	Col,
	Button,
	Card,
	CardBody,
	CardDeck,
	CardTitle,
	CardText,
	CardImg,
	CardImgOverlay,
} from "reactstrap";

import {
	FiHome,
	FiLinkedin,
	FiFacebook,
	FiSettings,
	FiFile,
	FiFolder,
	FiVideo,
	FiPhone,
	FiDisc,
} from "react-icons/fi";

const NavBar = () => {
	const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

	return isMobile ? (
		<div>
			<Row className="d-flex flex-row justify-content-center align-items-center">
				<Col sm="3">
					<div>
						<CardImg alt="Card image cap" src="./logo.png" width="20" />
					</div>
				</Col>
			</Row>

			<Row>
				<Col
					sm="12"
					className="d-flex flex-row justify-content-center align-items-center my-3">
					<NavLink className="inactive m-2" activeClassName="active" to="/">
						<a
							data-tooltip-id="home-tooltip"
							data-tooltip-content="Home"
							data-tooltip-place="bottom"
							data-tooltip-variant="warning"
							data-tooltip-offset={25}>
							<FiHome className="social-media" color="#ff9900" size={24} />
							<Tooltip id="home-tooltip" content="Home" place="bottom" />
						</a>
					</NavLink>

					<NavLink
						className="inactive m-2"
						activeClassName="active"
						to="/services">
						<a
							data-tooltip-id="services-tooltip"
							data-tooltip-content="Services"
							data-tooltip-place="bottom"
							data-tooltip-variant="warning"
							data-tooltip-offset={25}>
							<FiSettings className="social-media" color="#ff9900" size={24} />
							<Tooltip
								id="services-tooltip"
								content="Services"
								place="bottom"
							/>
						</a>
					</NavLink>

					<NavLink
						className="inactive m-2"
						activeClassName="active"
						to="/about">
						<a
							data-tooltip-id="about-tooltip"
							data-tooltip-content="About"
							data-tooltip-place="bottom"
							data-tooltip-variant="warning"
							data-tooltip-offset={25}>
							<FiFile className="social-media" color="#ff9900" size={24} />
							<Tooltip id="about-tooltip" content="About" place="bottom" />
						</a>
					</NavLink>

					<NavLink
						className="inactive m-2"
						activeClassName="active"
						to="/comments">
						<a
							data-tooltip-id="blog-tooltip"
							data-tooltip-content="Comments"
							data-tooltip-place="bottom"
							data-tooltip-variant="warning"
							data-tooltip-offset={25}>
							<FiFolder className="social-media" color="#ff9900" size={24} />
							<Tooltip id="blog-tooltip" content="Comments" place="bottom" />
						</a>
					</NavLink>

					<NavLink
						className="inactive m-2"
						activeClassName="active"
						to="/videos">
						<a
							data-tooltip-id="videos-tooltip"
							data-tooltip-content="Videos"
							data-tooltip-place="bottom"
							data-tooltip-variant="warning"
							data-tooltip-offset={25}>
							<FiVideo className="social-media" color="#ff9900" size={24} />
							<Tooltip id="videos-tooltip" content="Videos" place="bottom" />
						</a>
					</NavLink>
					<NavLink
						className="inactive m-2"
						activeClassName="active"
						to="/prompts">
						<a
							data-tooltip-id="prompts-tooltip"
							data-tooltip-content="Prompts"
							data-tooltip-place="bottom"
							data-tooltip-variant="warning"
							data-tooltip-offset={25}>
							<FiDisc className="social-media" color="#ff9900" size={24} />
							<Tooltip id="prompts-tooltip" content="Prompts" place="bottom" />
						</a>
					</NavLink>

					<NavLink
						className="inactive m-2"
						activeClassName="active"
						to="/contact">
						<a
							data-tooltip-id="contact-tooltip"
							data-tooltip-content="Contact"
							data-tooltip-place="bottom"
							data-tooltip-variant="warning"
							data-tooltip-offset={25}>
							<FiPhone className="social-media" color="#ff9900" size={24} />
							<Tooltip id="contact-tooltip" content="Contact" place="bottom" />
						</a>
					</NavLink>
				</Col>
			</Row>
		</div>
	) : (
		<div className="Header">
			<Row className="d-flex flex-row justify-content-center align-items-center">
				<Col sm="3">
					<div>
						<CardImg alt="Card image cap" src="./logo.png" width="20" />
					</div>
				</Col>

				<Col sm="1" className="button mx-3">
					<NavLink className="inactive" activeClassName="active" to="/">
						Home
					</NavLink>
				</Col>
				<Col sm="1" className="button mx-3">
					<NavLink className="inactive" activeClassName="active" to="/services">
						Services
					</NavLink>
				</Col>
				<Col sm="1" className="button mx-3">
					<NavLink className="inactive" activeClassName="active" to="/about">
						About
					</NavLink>
				</Col>

				<Col sm="1" className="button mx-3">
					<NavLink className="inactive" activeClassName="active" to="/comments">
						Comments
					</NavLink>
				</Col>
				<Col sm="1" className="button mx-3">
					<NavLink className="inactive" activeClassName="active" to="/videos">
						Videos
					</NavLink>
				</Col>
				<Col sm="1" className="button mx-3">
					<NavLink className="inactive" activeClassName="active" to="/prompts">
						Prompts
					</NavLink>
				</Col>
				<Col sm="1" className="button mx-3">
					<NavLink className="inactive" activeClassName="active" to="/contact">
						Contact
					</NavLink>
				</Col>
			</Row>
		</div>
	);
};

export default NavBar;

import { React, useState, useEffect, useContext, props } from "react";
import "../App.css";
import {
	Card,
	Row,
	Col,
	CardBody,
	CardDeck,
	CardTitle,
	CardText,
	CardImg,
	CardImgOverlay,
} from "reactstrap";
import Accord from "../Accordian";

const About = () => {
	return (
		<>
			<div
				className="Card"
				style={{
					backgroundColor: "transparent",
					zIndex: 1,
				}}>
				<div className="m-3">
					<Card className="card animate__animated animate__fadeInUp">
						<CardBody className="p-1">
							<CardTitle tag="h2">About</CardTitle>
							<Row className="d-flex flex-row justify-content-center align-items-center">
								<Col sm="12">
									<div>
										<CardText tag="h5">
											Bitnnovative empowers businesses to thrive in an AI-driven
											world by developing essential human skills, AI literacy,
											and AI-powered solutions. Our comprehensive approach
											combines training, coaching, and consulting services to
											bridge the gap between human capabilities and AI-driven
											innovation. We help businesses unlock the full potential
											of AI, drive growth, and gain a competitive edge. With AI
											Augment, organizations can confidently navigate the AI
											landscape, foster a culture of innovation, and achieve
											strategic success.
										</CardText>
									</div>
								</Col>

								<Row className="d-flex flex-row justify-content-center align-items-center pt-3">
									<Col sm="3">
										<div className="bar1">Elevate</div>
									</Col>
									<Col sm="3">
										<div className="bar2">Educate</div>
									</Col>
									<Col sm="3">
										<div className="bar3">Innovate</div>
									</Col>
								</Row>
							</Row>
							<Row className="d-flex flex-row justify-content-center align-items-center pt-3">
								<Accord />
							</Row>
						</CardBody>
					</Card>
				</div>
			</div>
		</>
	);
};

export default About;

import { React, useState, useEffect } from "react";
import "./App.css";
import { Card, CardBody, CardTitle, CardText, Row, Col } from "reactstrap";
import { FiHeart, FiBook, FiArrowRightCircle } from "react-icons/fi";

const CommentItem = ({ navigate, ...props }) => {
	const handleChange = () => {
		navigate(`/comments/${props.post.id}`);
	};
	return (
		<Card className="card animate__animated animate__fadeInUp m-3">
			<CardBody>
				<Row className="d-flex flex-row justify-content-center align-items-center">
					<Col sm="12">
						<div>
							<CardText tag="h4">{props.comment}</CardText>
						</div>
					</Col>
				</Row>
			</CardBody>
		</Card>

		// <a href={props.video.url}>
		// 	<h3>{props.video.title}</h3>
		// 	<p>{props.video.description}</p>
		// </a>
	);
};

export default CommentItem;

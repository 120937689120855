import { React, useState, useEffect, useContext, props } from "react";
import "../App.css";
import {
	Card,
	Row,
	Col,
	CardBody,
	CardDeck,
	CardTitle,
	CardText,
	CardImg,
	CardImgOverlay,
} from "reactstrap";
import Accord from "../Accordian";

const Services = () => {
	return (
		<>
			<div
				className="Card"
				style={{
					backgroundColor: "transparent",
				}}>
				<div className="m-5">
					<Card className="card animate__animated animate__fadeInUp">
						<CardBody>
							<CardTitle tag="h2">Services</CardTitle>
							<Row className="d-flex flex-row justify-content-center align-items-center">
								<Col sm="4">
									<div>
										<CardImg
											alt="Card image cap"
											bottom
											src="/s1.png"
											style={{
												height: 200,
												width: 220,
											}}
											width="100%"
										/>
										<CardText tag="h4">
											Human-Centered Skills Development
										</CardText>
									</div>
								</Col>
								<Col sm="4">
									<div>
										<CardImg
											alt="Card image cap"
											bottom
											src="/s2.png"
											style={{
												height: 200,
												width: 220,
											}}
											width="100%"
										/>
										<CardText tag="h4">
											AI Literacy and Responsible AI Use
										</CardText>
									</div>
								</Col>
								<Col sm="4">
									<div>
										<CardImg
											alt="Card image cap"
											bottom
											src="/s3.png"
											style={{
												height: 200,
												width: 220,
											}}
										/>
										<CardText tag="h4">AI-Powered Business Solutions</CardText>
									</div>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</div>
			</div>
		</>
	);
};

export default Services;

import { React, useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Card, CardBody, CardTitle, CardText, Row, Col } from "reactstrap";
import { FiHeart } from "react-icons/fi";
import axios from "axios";
import Iframe from "react-iframe";
import { useMediaQuery } from "react-responsive";

const Video = (props) => {
	const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
	return (
		<Card className="card animate__animated animate__fadeInUp my-3">
			<CardBody>
				<Row className="d-flex flex-row justify-content-center align-items-center">
					<Col sm="6">
						<div>
							{isMobile ? (
								<Iframe
									width="300"
									height="200"
									src={props.video.url}
									title="YouTube Video Player"
									frameborder="0"
									allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen></Iframe>
							) : (
								<Iframe
									width="500"
									height="315"
									src={props.video.url}
									title="YouTube Video Player"
									frameborder="0"
									allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen></Iframe>
							)}
						</div>
					</Col>
					<Col sm="6">
						<div>
							<CardText tag="h2">{props.video.title}</CardText>
						</div>
						<div>
							<CardText tag="p">{props.video.description}</CardText>
						</div>
					</Col>
				</Row>
			</CardBody>
		</Card>

		// <a href={props.video.url}>
		// 	<h3>{props.video.title}</h3>
		// 	<p>{props.video.description}</p>
		// </a>
	);
};

export default Video;

import { React, useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Card, CardBody, CardTitle, CardText, Row, Col } from "reactstrap";
import { FiHeart, FiBookOpen } from "react-icons/fi";
import { useParams } from "react-router-dom";
import blog_img from "./images/blog_img.png";
import { useMediaQuery } from "react-responsive";

const PromptTemplate = (props) => {
	const { postId } = useParams();
	// const selectedBlog = props.data
	// 	? props.data.find((blog) => blog.id === postId)
	// 	: null;

	const selectedPost = props.data.prompts.find((post) => post.id === postId);
	const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

	return isMobile ? (
		<Card className="card animate__animated animate__fadeInUp m-3">
			<CardBody>
				<CardTitle tag="h2">{selectedPost.title}</CardTitle>
				<CardTitle tag="h4">{selectedPost.date}</CardTitle>
				<div>
					<FiBookOpen size="38px" />
				</div>
				<Row className="d-flex flex-row justify-content-center align-items-center py-5">
					<Row sm="12">
						<div style={{ textAlign: "justify" }}>
							<CardText tag="h5">{selectedPost.description1}</CardText>
							<CardText tag="h5">{selectedPost.description2}</CardText>
						</div>
					</Row>
				</Row>
			</CardBody>
		</Card>
	) : (
		// <a href={props.video.url}>
		// 	<h3>{props.video.title}</h3>
		// 	<p>{props.video.description}</p>
		// </a>
		<Card className="card animate__animated animate__fadeInUp m-3">
			<CardBody>
				<CardTitle tag="h2">{selectedPost.title}</CardTitle>
				<CardTitle tag="h4">{selectedPost.date}</CardTitle>
				<div>
					<FiBookOpen size="38px" />
				</div>
				<Row className="d-flex flex-row justify-content-center align-items-center p-5">
					<Row sm="12">
						<div style={{ textAlign: "justify" }}>
							<CardText tag="h5">{selectedPost.description1}</CardText>
							<CardText tag="h5">{selectedPost.description2}</CardText>
						</div>
					</Row>
				</Row>
			</CardBody>
		</Card>
	);
};

export default PromptTemplate;

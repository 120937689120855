import { React, useState } from "react";
import logo from "./logo.svg";

import "./Anim.css";
import { Button, Card } from "reactstrap";
import Accord from "./Accordian";
import Webcard from "./Card";
import Headercard from "./HeaderCard";

import Content from "./Content";

function Anim() {
	return (
		<div className="sp-container">
			<div className="sp-content">
				<div className="sp-globe"></div>
				<h2 className="frame-1">ELEVATE</h2>
				<h2 className="frame-2">EDUCATE</h2>
				<h2 className="frame-3">INNOVATE</h2>
			</div>
		</div>
	);
}

export default Anim;

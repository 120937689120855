import { React, useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Card } from "reactstrap";
import { FiHeart } from "react-icons/fi";
import Video from "./Video";

const VideoList = (props, emptyHeading) => {
	console.log("in video list", props.data);

	const count = 0;
	let heading = emptyHeading;
	if (count > 0) {
		const noun = count > 1 ? "Videos" : "Video";
		heading = count + " " + noun;
	}
	return (
		<section>
			{/* <h2>{heading}</h2> */}

			{props.data &&
				props.data.video.map((video, index) => (
					<Video key={index} video={video} />
				))}
		</section>
	);
};
export default VideoList;

import { React, useState, useEffect, useContext, props } from "react";
import "../App.css";
import contactus from "../contactus.png";
import ContactForm from "../contactform";
import {
	Card,
	Row,
	Col,
	CardBody,
	CardDeck,
	CardTitle,
	CardText,
	CardImg,
	CardImgOverlay,
} from "reactstrap";
import Accord from "../Accordian";

const Contact = () => {
	const [sent, setSent] = useState(false);
	const handleSent = () => {
		setSent(true);
		setTimeout(() => {
			setSent(false);
		}, "6000");
	};
	return (
		<>
			<div
				className="Card"
				style={{
					backgroundColor: "transparent",
				}}>
				<Col
					md={{
						offset: 3,
						size: 6,
					}}
					sm="12">
					<div className="mx-5 ">
						{sent != true ? (
							<Card className="contact_card animate__animated animate__fadeInUp">
								<CardBody>
									<CardTitle tag="h2">Contact Us</CardTitle>

									<Row className="d-flex flex-row justify-content-center align-items-center">
										<Col sm="12">
											<div>
												<ContactForm messageSent={handleSent} />
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						) : (
							<Card className="contact_card animate__animated animate__fadeInUp">
								<CardBody>
									<CardTitle tag="h2">
										Thank You! <br></br> We shall get back to you soon
									</CardTitle>

									<Row className="d-flex flex-row justify-content-center align-items-center">
										<Col sm="12">
											<img
												className="swing-in-top-fwd"
												style={{ width: "100px" }}
												src={contactus}
											/>
										</Col>
									</Row>
								</CardBody>
							</Card>
						)}
					</div>
				</Col>
			</div>
		</>
	);
};

export default Contact;

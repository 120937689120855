import { React, useMemo, useState, useEffect, useContext } from "react";
import Anim from "../Anim_Copy";
import IconAnim from "../icon_anim";
import { useMediaQuery } from "react-responsive";
import Anim2 from "../Anim_2";
import { Hidden } from "@mui/material";
const Home = () => {
	const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
	return (
		<>
			<div>
				{isMobile ? <Anim2 /> : <Anim />}
				<IconAnim />
			</div>
		</>
	);
};

export default Home;
